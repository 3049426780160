/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PersonalizeImport } from './routes/personalize'
import { Route as OnboardingImport } from './routes/onboarding'
import { Route as LoginImport } from './routes/login'
import { Route as GrantPermissionsImport } from './routes/grant-permissions'
import { Route as MainImport } from './routes/_main'
import { Route as AuthCallbackIndexImport } from './routes/auth-callback/index'
import { Route as MainIndexImport } from './routes/_main/index'
import { Route as ErrorSessionExpiredImport } from './routes/error/session-expired'
import { Route as ErrorFailedToConnectImport } from './routes/error/failed-to-connect'
import { Route as AuthCallbackDesktopImport } from './routes/auth-callback/desktop'

// Create Virtual Routes

const SpotlightLazyImport = createFileRoute('/spotlight')()
const MiniChatLazyImport = createFileRoute('/mini-chat')()
const MeetingStatusLazyImport = createFileRoute('/meeting-status')()
const LinkLazyImport = createFileRoute('/link')()
const SharedChatIdLazyImport = createFileRoute('/shared-chat/$id')()
const PasswordEmailLazyImport = createFileRoute('/password/$email')()
const AccountSupportLazyImport = createFileRoute('/account/support')()
const AccountDeleteLazyImport = createFileRoute('/account/delete')()
const MainUpdateLazyImport = createFileRoute('/_main/update')()

// Create/Update Routes

const SpotlightLazyRoute = SpotlightLazyImport.update({
  id: '/spotlight',
  path: '/spotlight',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/spotlight.lazy').then((d) => d.Route))

const MiniChatLazyRoute = MiniChatLazyImport.update({
  id: '/mini-chat',
  path: '/mini-chat',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/mini-chat.lazy').then((d) => d.Route))

const MeetingStatusLazyRoute = MeetingStatusLazyImport.update({
  id: '/meeting-status',
  path: '/meeting-status',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/meeting-status.lazy').then((d) => d.Route),
)

const LinkLazyRoute = LinkLazyImport.update({
  id: '/link',
  path: '/link',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/link.lazy').then((d) => d.Route))

const PersonalizeRoute = PersonalizeImport.update({
  id: '/personalize',
  path: '/personalize',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/personalize.lazy').then((d) => d.Route))

const OnboardingRoute = OnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/onboarding.lazy').then((d) => d.Route))

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/login.lazy').then((d) => d.Route))

const GrantPermissionsRoute = GrantPermissionsImport.update({
  id: '/grant-permissions',
  path: '/grant-permissions',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/grant-permissions.lazy').then((d) => d.Route),
)

const MainRoute = MainImport.update({
  id: '/_main',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackIndexRoute = AuthCallbackIndexImport.update({
  id: '/auth-callback/',
  path: '/auth-callback/',
  getParentRoute: () => rootRoute,
} as any)

const MainIndexRoute = MainIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => MainRoute,
} as any).lazy(() => import('./routes/_main/index.lazy').then((d) => d.Route))

const SharedChatIdLazyRoute = SharedChatIdLazyImport.update({
  id: '/shared-chat/$id',
  path: '/shared-chat/$id',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/shared-chat.$id.lazy').then((d) => d.Route),
)

const PasswordEmailLazyRoute = PasswordEmailLazyImport.update({
  id: '/password/$email',
  path: '/password/$email',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/password.$email.lazy').then((d) => d.Route),
)

const AccountSupportLazyRoute = AccountSupportLazyImport.update({
  id: '/account/support',
  path: '/account/support',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/account/support.lazy').then((d) => d.Route),
)

const AccountDeleteLazyRoute = AccountDeleteLazyImport.update({
  id: '/account/delete',
  path: '/account/delete',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/account/delete.lazy').then((d) => d.Route),
)

const MainUpdateLazyRoute = MainUpdateLazyImport.update({
  id: '/update',
  path: '/update',
  getParentRoute: () => MainRoute,
} as any).lazy(() => import('./routes/_main.update.lazy').then((d) => d.Route))

const ErrorSessionExpiredRoute = ErrorSessionExpiredImport.update({
  id: '/error/session-expired',
  path: '/error/session-expired',
  getParentRoute: () => rootRoute,
} as any)

const ErrorFailedToConnectRoute = ErrorFailedToConnectImport.update({
  id: '/error/failed-to-connect',
  path: '/error/failed-to-connect',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackDesktopRoute = AuthCallbackDesktopImport.update({
  id: '/auth-callback/desktop',
  path: '/auth-callback/desktop',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_main': {
      id: '/_main'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof MainImport
      parentRoute: typeof rootRoute
    }
    '/grant-permissions': {
      id: '/grant-permissions'
      path: '/grant-permissions'
      fullPath: '/grant-permissions'
      preLoaderRoute: typeof GrantPermissionsImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/onboarding': {
      id: '/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof OnboardingImport
      parentRoute: typeof rootRoute
    }
    '/personalize': {
      id: '/personalize'
      path: '/personalize'
      fullPath: '/personalize'
      preLoaderRoute: typeof PersonalizeImport
      parentRoute: typeof rootRoute
    }
    '/link': {
      id: '/link'
      path: '/link'
      fullPath: '/link'
      preLoaderRoute: typeof LinkLazyImport
      parentRoute: typeof rootRoute
    }
    '/meeting-status': {
      id: '/meeting-status'
      path: '/meeting-status'
      fullPath: '/meeting-status'
      preLoaderRoute: typeof MeetingStatusLazyImport
      parentRoute: typeof rootRoute
    }
    '/mini-chat': {
      id: '/mini-chat'
      path: '/mini-chat'
      fullPath: '/mini-chat'
      preLoaderRoute: typeof MiniChatLazyImport
      parentRoute: typeof rootRoute
    }
    '/spotlight': {
      id: '/spotlight'
      path: '/spotlight'
      fullPath: '/spotlight'
      preLoaderRoute: typeof SpotlightLazyImport
      parentRoute: typeof rootRoute
    }
    '/auth-callback/desktop': {
      id: '/auth-callback/desktop'
      path: '/auth-callback/desktop'
      fullPath: '/auth-callback/desktop'
      preLoaderRoute: typeof AuthCallbackDesktopImport
      parentRoute: typeof rootRoute
    }
    '/error/failed-to-connect': {
      id: '/error/failed-to-connect'
      path: '/error/failed-to-connect'
      fullPath: '/error/failed-to-connect'
      preLoaderRoute: typeof ErrorFailedToConnectImport
      parentRoute: typeof rootRoute
    }
    '/error/session-expired': {
      id: '/error/session-expired'
      path: '/error/session-expired'
      fullPath: '/error/session-expired'
      preLoaderRoute: typeof ErrorSessionExpiredImport
      parentRoute: typeof rootRoute
    }
    '/_main/update': {
      id: '/_main/update'
      path: '/update'
      fullPath: '/update'
      preLoaderRoute: typeof MainUpdateLazyImport
      parentRoute: typeof MainImport
    }
    '/account/delete': {
      id: '/account/delete'
      path: '/account/delete'
      fullPath: '/account/delete'
      preLoaderRoute: typeof AccountDeleteLazyImport
      parentRoute: typeof rootRoute
    }
    '/account/support': {
      id: '/account/support'
      path: '/account/support'
      fullPath: '/account/support'
      preLoaderRoute: typeof AccountSupportLazyImport
      parentRoute: typeof rootRoute
    }
    '/password/$email': {
      id: '/password/$email'
      path: '/password/$email'
      fullPath: '/password/$email'
      preLoaderRoute: typeof PasswordEmailLazyImport
      parentRoute: typeof rootRoute
    }
    '/shared-chat/$id': {
      id: '/shared-chat/$id'
      path: '/shared-chat/$id'
      fullPath: '/shared-chat/$id'
      preLoaderRoute: typeof SharedChatIdLazyImport
      parentRoute: typeof rootRoute
    }
    '/_main/': {
      id: '/_main/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof MainIndexImport
      parentRoute: typeof MainImport
    }
    '/auth-callback/': {
      id: '/auth-callback/'
      path: '/auth-callback'
      fullPath: '/auth-callback'
      preLoaderRoute: typeof AuthCallbackIndexImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

interface MainRouteChildren {
  MainUpdateLazyRoute: typeof MainUpdateLazyRoute
  MainIndexRoute: typeof MainIndexRoute
}

const MainRouteChildren: MainRouteChildren = {
  MainUpdateLazyRoute: MainUpdateLazyRoute,
  MainIndexRoute: MainIndexRoute,
}

const MainRouteWithChildren = MainRoute._addFileChildren(MainRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof MainRouteWithChildren
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/link': typeof LinkLazyRoute
  '/meeting-status': typeof MeetingStatusLazyRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/auth-callback/desktop': typeof AuthCallbackDesktopRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/update': typeof MainUpdateLazyRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/account/support': typeof AccountSupportLazyRoute
  '/password/$email': typeof PasswordEmailLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
  '/': typeof MainIndexRoute
  '/auth-callback': typeof AuthCallbackIndexRoute
}

export interface FileRoutesByTo {
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/link': typeof LinkLazyRoute
  '/meeting-status': typeof MeetingStatusLazyRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/auth-callback/desktop': typeof AuthCallbackDesktopRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/update': typeof MainUpdateLazyRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/account/support': typeof AccountSupportLazyRoute
  '/password/$email': typeof PasswordEmailLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
  '/': typeof MainIndexRoute
  '/auth-callback': typeof AuthCallbackIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_main': typeof MainRouteWithChildren
  '/grant-permissions': typeof GrantPermissionsRoute
  '/login': typeof LoginRoute
  '/onboarding': typeof OnboardingRoute
  '/personalize': typeof PersonalizeRoute
  '/link': typeof LinkLazyRoute
  '/meeting-status': typeof MeetingStatusLazyRoute
  '/mini-chat': typeof MiniChatLazyRoute
  '/spotlight': typeof SpotlightLazyRoute
  '/auth-callback/desktop': typeof AuthCallbackDesktopRoute
  '/error/failed-to-connect': typeof ErrorFailedToConnectRoute
  '/error/session-expired': typeof ErrorSessionExpiredRoute
  '/_main/update': typeof MainUpdateLazyRoute
  '/account/delete': typeof AccountDeleteLazyRoute
  '/account/support': typeof AccountSupportLazyRoute
  '/password/$email': typeof PasswordEmailLazyRoute
  '/shared-chat/$id': typeof SharedChatIdLazyRoute
  '/_main/': typeof MainIndexRoute
  '/auth-callback/': typeof AuthCallbackIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/link'
    | '/meeting-status'
    | '/mini-chat'
    | '/spotlight'
    | '/auth-callback/desktop'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/update'
    | '/account/delete'
    | '/account/support'
    | '/password/$email'
    | '/shared-chat/$id'
    | '/'
    | '/auth-callback'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/link'
    | '/meeting-status'
    | '/mini-chat'
    | '/spotlight'
    | '/auth-callback/desktop'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/update'
    | '/account/delete'
    | '/account/support'
    | '/password/$email'
    | '/shared-chat/$id'
    | '/'
    | '/auth-callback'
  id:
    | '__root__'
    | '/_main'
    | '/grant-permissions'
    | '/login'
    | '/onboarding'
    | '/personalize'
    | '/link'
    | '/meeting-status'
    | '/mini-chat'
    | '/spotlight'
    | '/auth-callback/desktop'
    | '/error/failed-to-connect'
    | '/error/session-expired'
    | '/_main/update'
    | '/account/delete'
    | '/account/support'
    | '/password/$email'
    | '/shared-chat/$id'
    | '/_main/'
    | '/auth-callback/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  MainRoute: typeof MainRouteWithChildren
  GrantPermissionsRoute: typeof GrantPermissionsRoute
  LoginRoute: typeof LoginRoute
  OnboardingRoute: typeof OnboardingRoute
  PersonalizeRoute: typeof PersonalizeRoute
  LinkLazyRoute: typeof LinkLazyRoute
  MeetingStatusLazyRoute: typeof MeetingStatusLazyRoute
  MiniChatLazyRoute: typeof MiniChatLazyRoute
  SpotlightLazyRoute: typeof SpotlightLazyRoute
  AuthCallbackDesktopRoute: typeof AuthCallbackDesktopRoute
  ErrorFailedToConnectRoute: typeof ErrorFailedToConnectRoute
  ErrorSessionExpiredRoute: typeof ErrorSessionExpiredRoute
  AccountDeleteLazyRoute: typeof AccountDeleteLazyRoute
  AccountSupportLazyRoute: typeof AccountSupportLazyRoute
  PasswordEmailLazyRoute: typeof PasswordEmailLazyRoute
  SharedChatIdLazyRoute: typeof SharedChatIdLazyRoute
  AuthCallbackIndexRoute: typeof AuthCallbackIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  MainRoute: MainRouteWithChildren,
  GrantPermissionsRoute: GrantPermissionsRoute,
  LoginRoute: LoginRoute,
  OnboardingRoute: OnboardingRoute,
  PersonalizeRoute: PersonalizeRoute,
  LinkLazyRoute: LinkLazyRoute,
  MeetingStatusLazyRoute: MeetingStatusLazyRoute,
  MiniChatLazyRoute: MiniChatLazyRoute,
  SpotlightLazyRoute: SpotlightLazyRoute,
  AuthCallbackDesktopRoute: AuthCallbackDesktopRoute,
  ErrorFailedToConnectRoute: ErrorFailedToConnectRoute,
  ErrorSessionExpiredRoute: ErrorSessionExpiredRoute,
  AccountDeleteLazyRoute: AccountDeleteLazyRoute,
  AccountSupportLazyRoute: AccountSupportLazyRoute,
  PasswordEmailLazyRoute: PasswordEmailLazyRoute,
  SharedChatIdLazyRoute: SharedChatIdLazyRoute,
  AuthCallbackIndexRoute: AuthCallbackIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_main",
        "/grant-permissions",
        "/login",
        "/onboarding",
        "/personalize",
        "/link",
        "/meeting-status",
        "/mini-chat",
        "/spotlight",
        "/auth-callback/desktop",
        "/error/failed-to-connect",
        "/error/session-expired",
        "/account/delete",
        "/account/support",
        "/password/$email",
        "/shared-chat/$id",
        "/auth-callback/"
      ]
    },
    "/_main": {
      "filePath": "_main.tsx",
      "children": [
        "/_main/update",
        "/_main/"
      ]
    },
    "/grant-permissions": {
      "filePath": "grant-permissions.ts"
    },
    "/login": {
      "filePath": "login.ts"
    },
    "/onboarding": {
      "filePath": "onboarding.ts"
    },
    "/personalize": {
      "filePath": "personalize.ts"
    },
    "/link": {
      "filePath": "link.lazy.tsx"
    },
    "/meeting-status": {
      "filePath": "meeting-status.lazy.tsx"
    },
    "/mini-chat": {
      "filePath": "mini-chat.lazy.tsx"
    },
    "/spotlight": {
      "filePath": "spotlight.lazy.tsx"
    },
    "/auth-callback/desktop": {
      "filePath": "auth-callback/desktop.tsx"
    },
    "/error/failed-to-connect": {
      "filePath": "error/failed-to-connect.ts"
    },
    "/error/session-expired": {
      "filePath": "error/session-expired.ts"
    },
    "/_main/update": {
      "filePath": "_main.update.lazy.tsx",
      "parent": "/_main"
    },
    "/account/delete": {
      "filePath": "account/delete.lazy.tsx"
    },
    "/account/support": {
      "filePath": "account/support.lazy.tsx"
    },
    "/password/$email": {
      "filePath": "password.$email.lazy.tsx"
    },
    "/shared-chat/$id": {
      "filePath": "shared-chat.$id.lazy.tsx"
    },
    "/_main/": {
      "filePath": "_main/index.ts",
      "parent": "/_main"
    },
    "/auth-callback/": {
      "filePath": "auth-callback/index.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
