import { captureException } from '@sentry/react';
import { invoke } from '@tauri-apps/api/core';
import { RESET } from 'jotai/utils';
import posthog from 'posthog-js';
import { z } from 'zod';

import { AppCapability, inBrowser, isCapable } from './capabilities';
import { stopContextCollection } from './context_collection';
import { getLocalStorageItem } from './localStorage';

import { getEnvVar } from '@/env';
import { TokenExpiredError } from '@/error';
import { queryClient } from '@/query/client';
import { router } from '@/router';
import { useAppStore } from '@/store';
import { isCollectingContextAtom } from '@/stores/atoms/context_collection';
import { userAtom } from '@/stores/atoms/user';
import jotaiStore from '@/stores/jotaiStore';

export const AUTH_TOKEN_REFRESH_INTERVAL = 1000 * 60 * 17.0;
export const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 'access_token';
export const REFRESH_TOKEN_LOCAL_STORAGE_KEY = 'refresh_token';

export const AuthTokensResponseSchema = z.object({
  access_token: z.string(),
  refresh_token: z.string().optional(),
});

export function getAuthHeader() {
  return {
    Authorization: `Bearer ${getLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY)}`,
  };
}

export async function logoutUser(redirect = true) {
  if (!isCapable(AppCapability.MainWindowExclusive))
    throw new Error(
      'logout user can only be called in the main window or in a browser window'
    );

  try {
    if (jotaiStore.get(isCollectingContextAtom)) {
      await stopContextCollection();
    }

    await revokeRefreshToken(
      getLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
    );

    resetAuthTokens();

    queryClient.clear();

    jotaiStore.set(userAtom, RESET);

    useAppStore.getState().reset();

    posthog.reset();

    if (!redirect) return;

    if (inBrowser()) {
      const redirectUri = encodeURIComponent(window.location.origin + '/login');

      window.location.href = `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/v2/logout?client_id=${getEnvVar('VITE_AUTH0_CLIENT_ID')}&returnTo=${redirectUri}`;
      return;
    }

    router.navigate({ to: '/login' });
  } catch (error) {
    useAppStore.getState().setToastData({
      title: 'Error',
      description: (error as Error)?.message,
      kind: 'error',
    });
  }
}

export async function refreshAuthTokens(refreshToken: string) {
  if (isCapable(AppCapability.BackgroundAuthTokenRefresh)) {
    try {
      const auth_tokens = await invoke<{
        access_token: string;
        refresh_token: string;
      }>('get_auth_tokens');

      const { data, error } = AuthTokensResponseSchema.safeParse(auth_tokens);

      if (error) return Promise.reject(new TokenExpiredError(error.toString()));

      return data;
    } catch (err) {
      const message = (
        (err as Error)?.message ?? (err as string)
      )?.toLowerCase();

      if (message.includes('expired')) {
        throw new TokenExpiredError(message);
      }
      if (message.includes('forbidden')) {
        const error = new TokenExpiredError(message);
        captureException(error);
        throw error;
      }

      throw new Error(message);
    }
  }

  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/token`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
        refresh_token: refreshToken,
      }),
    }
  );

  switch (response.status) {
    case 401:
      throw new TokenExpiredError();
    case 403:
      const error = new TokenExpiredError(await response.text());

      captureException(error);

      throw error;
    case 200:
      return AuthTokensResponseSchema.parse(await response.json());
    default:
      throw new Error(`Unexpected status: ${response.status}`);
  }
}

export function isAuthenticated() {
  return !!localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
}

export function resetAuthTokens() {
  localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  localStorage.removeItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY);
}

export function decodeIdToken(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(atob(base64));
}

async function revokeRefreshToken(token: string) {
  if (isCapable(AppCapability.BackgroundAuthTokenRefresh))
    return invoke('revoke_auth_tokens');

  const response = await fetch(
    `https://${getEnvVar('VITE_AUTH0_DOMAIN')}/oauth/revoke`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token,
        client_id: getEnvVar('VITE_AUTH0_CLIENT_ID'),
      }),
    }
  );

  if (!response.ok) {
    throw new Error('Failed to revoke refresh token');
  }
}
