import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { LucideIcon, LucideProps } from 'lucide-react';

type IconButtonIconSize = '15px' | '13px' | '18px';

interface IconButtonProps {
  icon: IconDefinition | LucideIcon;
  onClick?: React.MouseEventHandler;
  hoverIcon?: IconDefinition | LucideIcon;
  disabled?: boolean;
  className?: string;
  size?: IconButtonIconSize;
  iconProps?: Partial<FontAwesomeIconProps> | Partial<LucideProps>;
  padded?: boolean;
  title?: string;
}

export default function IconButton({
  icon,
  onClick,
  hoverIcon,
  size = '15px',
  disabled = false,
  className = '',
  iconProps,
  padded = false,
  title,
}: IconButtonProps) {
  const paddedSizeCl = !padded
    ? ' '
    : `hover:bg-darkFieldHover hover:rounded-md ${
        size === '18px' ? 'w-10 h-10' : size === '15px' ? 'w-8 h-8' : 'w-7 h-7'
      }`;

  const isFontAwesomeIcon = (
    icon: IconDefinition | LucideIcon
  ): icon is IconDefinition => {
    return 'prefix' in icon && 'iconName' in icon;
  };

  const renderIcon = (icon: IconDefinition | LucideIcon, className: string) => {
    if (isFontAwesomeIcon(icon)) {
      const faProps = iconProps as Partial<FontAwesomeIconProps>;
      return (
        <FontAwesomeIcon
          icon={icon}
          style={{ fontSize: size }}
          className={className}
          {...faProps}
        />
      );
    } else {
      const LucideIconComponent = icon;
      const lucideProps = iconProps as Partial<LucideProps>;
      return (
        <LucideIconComponent
          size={parseInt(size)}
          className={className}
          {...lucideProps}
        />
      );
    }
  };

  return (
    <motion.button
      whileTap={disabled ? undefined : { scale: 0.8 }}
      style={{ originX: '50%', originY: '50%' }}
      className={`disabled:opacity-80 group relative ${className} ${paddedSizeCl}`}
      onClick={onClick ?? undefined}
      disabled={disabled}
      title={title}
    >
      {renderIcon(
        icon,
        `
        duration-75
        transition-colors
        text-darkSecondary
        ${!disabled && 'group-hover:text-darkPrimary'}
        ${hoverIcon && 'group-hover:hidden'}
        ${iconProps?.className ?? ''}
      `.trim()
      )}
      {hoverIcon &&
        renderIcon(
          hoverIcon,
          `
        duration-75
        transition-colors
        text-darkSecondary
        ${!disabled && 'group-hover:text-darkPrimary'}
        hidden group-hover:block
        ${iconProps?.className ?? ''}
      `.trim()
        )}
    </motion.button>
  );
}
