import { queryOptions } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { UnableToConnectToServerError } from '@/error';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  AUTH_TOKEN_REFRESH_INTERVAL,
  isAuthenticated,
  logoutUser,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
  refreshAuthTokens,
} from '@/utils/auth';
import { AppCapability, isCapable } from '@/utils/capabilities';
import { checkConnection } from '@/utils/connection';
import { getLocalStorageItem, setLocalStorageItem } from '@/utils/localStorage';
import { debugToast } from '@/utils/toast';

export const IS_AUTH_VALID_QUERY_KEY = ['is-auth-valid'];

export const isAuthValidQueryOptions = () =>
  queryOptions({
    queryKey: IS_AUTH_VALID_QUERY_KEY,
    retry: false,
    refetchInterval: AUTH_TOKEN_REFRESH_INTERVAL,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    queryFn: async () => {
      try {
        if (!isCapable(AppCapability.MainWindowExclusive)) {
          const message = 'only main window is allowed to refresh auth tokens';

          debugToast(message);

          throw new Error(message);
        }

        if (!isAuthenticated()) return false;

        debugToast('auth tokens refreshing...');

        posthog.capture('user_auth_tokens_will_refresh');

        const { access_token, refresh_token = undefined } =
          await refreshAuthTokens(
            getLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY)
          );

        setLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, access_token);

        if (refresh_token)
          setLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, refresh_token);

        debugToast('auth tokens refreshed');

        posthog.capture('user_auth_tokens_did_refresh');

        return true;
      } catch (error) {
        debugToast('checking for internet connectivity');

        if (!(await checkConnection())) {
          debugToast(
            'unable to refresh token, reason: unable to connect to server'
          );

          throw new UnableToConnectToServerError();
        }

        debugToast(`unable to refresh token, reason: ${JSON.stringify(error)}`);

        throw error;
      }
    },
  });

export const logoutUserMutationOptions = () => ({
  mutationKey: ['logout-user'],
  retry: false,
  mutationFn: async ({ redirect = true }: { redirect: boolean }) =>
    logoutUser(redirect),
});
