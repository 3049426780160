import { invoke } from '@tauri-apps/api/core';
import { findLastIndex } from 'lodash';
import posthog from 'posthog-js';
import { create, StoreApi } from 'zustand';

import {
  ChatTitle,
  NEW_CHAT_TITLE,
} from './components/ui/window-title/ChatTitle';
import { ChatServiceError } from './error';
import { logError } from './lib/errorLogging';
import { Chat, chatCreateQueryOptions } from './query/chat';
import { Summary } from './query/summaries';
import { chatModeAtom } from './stores/atoms/chat';
import { onboardingAtom } from './stores/atoms/onboarding';
import { userAtom } from './stores/atoms/user';
import jotaiStore from './stores/jotaiStore';
import { ChatStatus, PartialAnswers } from './types/dataclasses';
import {
  delete_inputs,
  get_chat_reply,
  get_personal_access_token,
  get_shared_chat,
  speech_to_text,
  stop_chat_with_context,
  text_to_speech,
} from './utils/api';
import {
  ACCESS_TOKEN_LOCAL_STORAGE_KEY,
  REFRESH_TOKEN_LOCAL_STORAGE_KEY,
} from './utils/auth';
import { inBrowser } from './utils/capabilities';
import { fakeUserStore } from './utils/fake/fakeUser';
import { blobToBase64, getFilesFromPaths } from './utils/files';
import { setLocalStorageItem } from './utils/localStorage';
import { taggedLog } from './utils/logging';
import { retryWithBackoff } from './utils/promise';
import { setWindowTitle } from './utils/window';

import { ChatServiceUserError } from '@/error';
import { queryClient } from '@/query/client';
import { router } from '@/router';

export const NEW_CHAT_DEFAULT_TITLE = 'New Chat';
export const DEFAULT_CATEGORY_FILTER = '';

// Used to disable voice mode in platforms that don't support it (e.g. Android)
let voiceModeSupported = true;

if (!inBrowser()) {
  invoke('voice_mode_supported').then((enabled) => {
    console.warn('VOICE MODE SUPPORTED ' + enabled);
    voiceModeSupported = Boolean(enabled);
  });
}

export const VOICE_MODE_SUPPORTED = () => voiceModeSupported;

interface AppStore {
  // App / UI States
  toastData: {
    title: string;
    description: string;
    kind: 'info' | 'error';
  } | null;
  setToastData: (
    data: {
      title: string;
      description: string;
      kind: 'info' | 'error';
    } | null
  ) => void;
  // Summary/History UI
  focusedSummary: Summary | null;
  setFocusedSummary: (summary: Summary | null) => void;
  // Chat UI
  focusedChat: Chat | null;
  setFocusedChat: (chat: Chat | null) => void;
  stopFocusedChat: () => void;
  // Meeting UI
  focusedMeetingId: number | null;
  setFocusedMeetingId: (meetingId: number | null) => void;
  // Voice features
  speechToText: (audio: Blob) => Promise<string>;
  textToSpeech: (text: string) => Promise<string>;
  useVoiceMode: boolean;
  setVoiceMode: (enabled: boolean) => void;
  isProcessingVoice: boolean;
  setProcessingVoice: (processing: boolean) => void;
  ttsMessages: { text: string; isLast: boolean }[];
  setTtsMessages: (messages: { text: string; isLast: boolean }[]) => void;
  isFakeUser: boolean;
  setIsFakeUser: (state: boolean) => void;
  // Chat
  fetchSharedChat: (chatId: string, chatPublicToken: string) => Promise<void>;
  createNewChatAndSetAsFocusedChat: (
    messageText: string,
    isRetry?: boolean
  ) => Promise<number | undefined>;
  requestNewChat: () => Promise<void>;
  partialAnswers: PartialAnswers;
  sendQuestion: (
    someChatId: number,
    text: string,
    attachedFiles: string[],
    isRetry?: boolean
  ) => Promise<void>;
  settingsOpen: boolean;
  setSettingsOpen: (state: boolean) => void;
  deleteInputs: (timestamp: number) => Promise<boolean>;
  cmdModalOpen: boolean;
  setCmdModalOpen: (show: boolean) => void;
  shareModalOpen: boolean;
  setShareModalOpen: (open: boolean) => void;
  personalAccessToken: string | null;
  fetchPersonalAccessToken: () => Promise<void>;
  reset: () => void;
  focusedChatErrorType: ChatServiceError | null;
}

const initialState = {
  cmdModalOpen: false,
  focusedSummary: null,
  focusedChat: null,
  focusedMeetingId: null,
  toastData: null,
  isFakeUser: false,
  partialAnswers: {},
  settingsOpen: false,
  ttsMessages: [],
  useVoiceMode: false,
  isProcessingVoice: false,
  shareModalOpen: false,
  personalAccessToken: null,
  focusedChatErrorType: null,
};

const TEMP_CHAT_ID = -1;
export const setFakeJohnDoeUser = () => {
  setLocalStorageItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, 'fake-access-token');
  setLocalStorageItem(REFRESH_TOKEN_LOCAL_STORAGE_KEY, 'fake-access-token');
  jotaiStore.set(onboardingAtom, {
    onboarding_progress: 1,
  });

  const store = useAppStore.getState();

  jotaiStore.set(userAtom, fakeUserStore.user);

  queryClient.setQueryData(['chats'], fakeUserStore.chats);
  store.setIsFakeUser(true);

  router.navigate({ to: '/', replace: true });
};

interface SSEResponse {
  event_type?:
    | 'new_assistant_message'
    | 'finish_one_assistant_message'
    | 'update_last_message';
  content?: string;
  history?: Array<Array<string>>;
}
type BaseStoreType = StoreApi<AppStore>;

const baseStore = (
  set: BaseStoreType['setState'],
  get: BaseStoreType['getState']
): AppStore => {
  return {
    ...initialState,
    setCmdModalOpen: (state: boolean) => set({ cmdModalOpen: state }),
    setFocusedSummary: (summary: Summary | null) => {
      set({ focusedSummary: summary });
    },
    setFocusedChat: (chat: Chat | null) => {
      if (chat) {
        posthog.capture('$pageview', { pagename: 'CHAT THREAD' });
      }

      set({ focusedChat: chat, focusedChatErrorType: null });

      setWindowTitle({
        title: <ChatTitle chatId={chat?.chat_id ?? -1} />,
        altTitle: chat?.title ?? NEW_CHAT_TITLE,
      });
    },
    setFocusedMeetingId: (meetingId: number | null) => {
      set({ focusedMeetingId: meetingId });
    },
    stopFocusedChat: () => {
      const { focusedChat, partialAnswers } = get();
      if (!focusedChat) return;
      const chatId = focusedChat.chat_id;
      const partialAnswer = partialAnswers[chatId]?.partialAnswer ?? '';

      const interruptMessageForBE = [
        'assistant',
        `text:${partialAnswer}...`,
        'text:<system>Note to assistant: user requested early stop of your above message before you fully generated it via button in UI. This is completely normal feature, nothing wrong</system>',
      ];
      const updatedChatHistory = [
        ...focusedChat.chat_history,
        interruptMessageForBE.slice(0, -1), // Don't save internal text, just user-visible text
      ];

      const chats = queryClient.getQueryData<Chat[]>(['chats']) ?? [];
      queryClient.setQueryData(
        ['chats'],
        chats.map((chat) =>
          chat.chat_id === chatId
            ? { ...chat, chat_history: updatedChatHistory }
            : chat
        )
      );

      set({
        focusedChat: {
          ...focusedChat,
          chat_history: updatedChatHistory,
        },
        partialAnswers: {
          ...partialAnswers,
          [chatId]: {
            status: ChatStatus.NotSubmitted,
            isStoppedByUser: true,
            partialAnswer: '',
            messageIndex: updatedChatHistory.length - 1,
          },
        },
      });

      stop_chat_with_context(chatId, interruptMessageForBE).catch((error) => {
        logError(error, 'Error stopping chat');
      });
    },
    setToastData: (toastData: Parameters<AppStore['setToastData']>[0]) =>
      set({ toastData }),
    setIsFakeUser: (isFakeUser: boolean) => {
      set({ isFakeUser });
      setLocalStorageItem('FAKE_USER', true);
    },
    fetchSharedChat: async (chatId: string, chatPublicToken: string) => {
      const chat = await get_shared_chat(chatId, chatPublicToken);
      set({ focusedChat: chat });
    },
    requestNewChat: async () => {
      set({
        focusedChat: null,
      });
    },
    createNewChatAndSetAsFocusedChat: async (
      messageText: string,
      isRetry: boolean = false
    ) => {
      let updatedFocusedChat = {
        title: NEW_CHAT_DEFAULT_TITLE, // TODO: Should just read from get().chats[id].title to avoid delay bug
        chat_id: TEMP_CHAT_ID,
        chat_history: [['user', `text:${messageText}`]],
        date: new Date().toISOString(),
        starred: false,
      };

      let chats = queryClient.getQueryData<Chat[]>(['chats']) ?? [];
      // Add the new chat placeholder to the chats array if it's not already there, i.e. if isRetry is false
      // If isRetry is true, chats already contains the current focused chat with temp id TEMP_CHAT_ID
      if (!isRetry) {
        chats = [updatedFocusedChat, ...chats]; // We will use it after we successfully fetch the new chat id
        queryClient.setQueryData(['chats'], chats);
      }
      set((prev) => ({
        focusedChat: updatedFocusedChat,
        partialAnswers: {
          ...prev.partialAnswers,
          [TEMP_CHAT_ID]: {
            status: ChatStatus.Thinking,
            partialAnswer: '',
            messageIndex: 0,
          },
        },
      }));

      const chatId = await queryClient
        .fetchQuery(chatCreateQueryOptions())
        .catch((error) => {
          set((prev) => ({
            focusedChatErrorType:
              error instanceof ChatServiceUserError
                ? error.errorType
                : ChatServiceError.GeneralError,
            toastData: {
              title: 'Error creating a new chat',
              description:
                error instanceof Error
                  ? error.message
                  : 'Unknown error occurred',
              kind: 'error',
            },
            partialAnswers: {
              ...prev.partialAnswers,
              [TEMP_CHAT_ID]: {
                status: ChatStatus.NotSubmitted,
                partialAnswer: '',
                messageIndex: 0,
              },
            },
          }));
          throw error;
        });

      if (!chatId) return;

      updatedFocusedChat = {
        ...updatedFocusedChat,
        chat_id: chatId,
      };

      set({ focusedChat: updatedFocusedChat });

      // Update the placeholder chat (either newly added or pre-existing) in the query cache with the fetched chat id
      queryClient.setQueryData(
        ['chats'],
        chats.map((chat) =>
          chat.chat_id === TEMP_CHAT_ID ? updatedFocusedChat : chat
        )
      );
      return chatId;
    },
    sendQuestion: async (
      someChatId: number,
      text: string,
      attachedFiles: string[],
      isRetry = false
    ) => {
      function finishOneAssistantMessage(chatId: number, expectMore: boolean) {
        const { focusedChat, partialAnswers } = get();

        const someChats = queryClient.getQueryData<Chat[]>(['chats']) ?? [];

        const chat = someChats.find((chat) => chat.chat_id === chatId);

        if (!chat) return;

        const isFocusedChat = focusedChat
          ? chat.chat_id === focusedChat.chat_id
          : false;

        // Append the new assistant message to the chat history,
        // set the partial answer to empty string to indicate we're no longer in the middle of a
        // streaming assistant message, set the thinking and streaming flags depending on whether
        // more assistant messages are coming, indicate that user's attached files have definetely
        // been incorporated into the chat.

        taggedLog(
          'VITE_LOG_CHAT_HIST_EVENTS',
          'finishOneAssistantMessage: len of chat history will now be ',
          chat.chat_history.length + 1,
          'expectMore',
          expectMore
        );
        set({
          ...(isFocusedChat
            ? {
                focusedChat: {
                  ...(focusedChat as Chat),
                  chat_history: [
                    ...(focusedChat as Chat).chat_history,
                    ['assistant', `text:${streamedMessage}`], // TODO: We are making the assumption that a message from the
                    // assistant is always a single text block. This works for now but if we wanted to have a "sources:"
                    // block added right away or more text blocks or other blocks like images, we would need to change this.
                  ],
                },
              }
            : null),
          partialAnswers: {
            ...partialAnswers,
            [chat.chat_id]: {
              status: expectMore
                ? ChatStatus.Thinking
                : ChatStatus.NotSubmitted,
              partialAnswer: '',
              messageIndex,
            },
          },
        });

        // Reset streamedMessage, so that the possible next assistant message doesn't get jumbled
        // with the previous one
        streamedMessage = '';
      }

      const { focusedChat, partialAnswers } = get();

      const userChats = queryClient.getQueryData<Chat[]>(['chats']) ?? [];

      const someChat = userChats?.find((chat) => chat.chat_id === someChatId);

      const checkIsFocusedChat = () => {
        const focusedChat = get().focusedChat;

        return someChat && focusedChat
          ? focusedChat?.chat_id === someChat?.chat_id
          : false;
      };

      const isFocusedChat = checkIsFocusedChat();

      const chat = isFocusedChat ? (focusedChat as Chat) : someChat;

      const fileAttachments = await getFilesFromPaths(attachedFiles);

      if (isFocusedChat)
        set({
          focusedChatErrorType: null,
        });

      // Construct updatedFocusedChat
      let updatedFocusedChat: Chat;
      if (isRetry && chat) {
        if (chat.chat_id === TEMP_CHAT_ID) {
          await get().createNewChatAndSetAsFocusedChat(text, true);
          updatedFocusedChat = get().focusedChat as Chat;
        }
        // Remove the failed assistant message
        const lastUserMessageIndex = findLastIndex(
          chat.chat_history,
          ([role]) => role === 'user'
        );
        if (lastUserMessageIndex === -1) return; // Should not happen
        updatedFocusedChat = {
          ...chat,
          chat_history: chat.chat_history.slice(0, lastUserMessageIndex + 1),
        };
      } // Handle new chat creation
      else if (!chat) {
        await get().createNewChatAndSetAsFocusedChat(text);
        updatedFocusedChat = get().focusedChat as Chat;
      } else {
        if (partialAnswers[chat.chat_id]?.status === ChatStatus.Thinking)
          return;

        const fileData = await Promise.all(
          fileAttachments.map(async (file) => {
            try {
              return await blobToBase64(file);
            } catch {
              return null;
            }
          })
        );

        // Filter out any failed conversions (optional)
        const validFileData = fileData.filter((data) => data !== null);

        updatedFocusedChat = {
          ...chat,
          chat_history: [
            ...chat.chat_history,
            ['user', `text:${text}`, ...validFileData],
          ],
        };
      }
      const chatId = updatedFocusedChat.chat_id;
      let streamedMessage = '';
      let messageIndex = updatedFocusedChat.chat_history.length;
      // Indicates the index in the chat history that the current/streaming assistant message will occupy
      // "let" because it can change if the assistant sends more than one message during the same request

      posthog.capture('user_chat_event', {
        actionName: 'Message_Sent',
        chatThread: updatedFocusedChat.title,
        source: 'Little Bird',
      });

      queryClient.setQueryData(
        ['chats'],
        userChats.map((chat) =>
          chat.chat_id === chatId
            ? { ...chat, chat_history: [...updatedFocusedChat.chat_history] }
            : chat
        )
      );

      set({
        ...(isFocusedChat
          ? { focusedChat: updatedFocusedChat, focusedChatErrorType: null }
          : null),
        partialAnswers: {
          ...partialAnswers,
          [chatId]: {
            status: ChatStatus.Thinking,
            partialAnswer: '',
            messageIndex,
          },
        },
      });

      const onServerSentMessage = (response: unknown) => {
        const data = response as SSEResponse;
        const freshPartialAnswers = get().partialAnswers;
        const partialAnswerInfo = freshPartialAnswers[chatId];
        const isFocusedChat = checkIsFocusedChat();

        if (partialAnswerInfo?.isStoppedByUser) {
          taggedLog(
            'VITE_LOG_CHAT_HIST_EVENTS',
            'isStoppedByUser is true, bailing out of SSE event handler'
          );
          return;
        }
        if (data.event_type === 'update_last_message') {
          if (!data.history) throw new Error('No updated last message');
          taggedLog(
            'VITE_LOG_CHAT_HIST_EVENTS',
            'update_last_message, num blocks in updated last message',
            data.history[0].length // Only one message is sent in this event, the updated last message
          );

          if (isFocusedChat)
            set({
              focusedChat: {
                ...updatedFocusedChat,
                chat_history: [
                  ...(get().focusedChat?.chat_history.slice(0, -1) ?? []),
                  data.history[0],
                ], // TODO: Do we have to update chats as well?
              },
            });
        } else if (data.event_type === 'new_assistant_message') {
          if (!data.history)
            throw new Error('No history in new_assistant_message');
          taggedLog(
            'VITE_LOG_CHAT_HIST_EVENTS',
            'new_assistant_message, len of response.history',
            data.history.length,
            'messageIndex',
            messageIndex
          );
          messageIndex = data.history.length;
          set({
            ...(isFocusedChat
              ? {
                  focusedChat: {
                    ...updatedFocusedChat,
                    chat_history: data.history, // TODO: Do we have to update chats as well?
                  },
                }
              : null),
            partialAnswers: {
              ...freshPartialAnswers,
              [chatId]: {
                ...partialAnswerInfo,
                status: ChatStatus.Thinking,
                messageIndex,
              },
            },
          });
        } else if (data.event_type === 'finish_one_assistant_message') {
          // Finish one message but don't end stream
          finishOneAssistantMessage(chatId, true);
        } else if (data.content) {
          // Standard case: new token (check for content to avoid adding "null")
          streamedMessage += data.content;
          set({
            partialAnswers: {
              ...freshPartialAnswers,
              [chatId]: {
                status: ChatStatus.Streaming,
                partialAnswer: streamedMessage,
                messageIndex,
              },
            },
          });
        }
      };

      try {
        await retryWithBackoff(() =>
          get_chat_reply(
            text,
            chatId,
            jotaiStore.get(chatModeAtom),
            fileAttachments,
            onServerSentMessage
          )
        );

        if (!get().partialAnswers[chatId].isStoppedByUser) {
          finishOneAssistantMessage(chatId, false);
        }
      } catch (error) {
        if (get().partialAnswers[chatId].isStoppedByUser) {
          return;
        }

        set({
          ...(isFocusedChat
            ? {
                focusedChatErrorType:
                  error instanceof ChatServiceUserError
                    ? error.errorType
                    : ChatServiceError.GeneralError,
              }
            : null),
          toastData: {
            title: 'Error getting a reply',
            description:
              error instanceof Error ? error.message : 'Unknown error occurred',
            kind: 'error',
          },
          partialAnswers: {
            ...partialAnswers,
            [updatedFocusedChat.chat_id]: {
              status: ChatStatus.NotSubmitted,
              partialAnswer: '',
              messageIndex,
            },
          },
        });
      }
    },
    speechToText: async (audio: Blob) => {
      return speech_to_text(audio);
    },
    textToSpeech: async (text: string) => {
      return text_to_speech(text);
    },
    setVoiceMode: (enabled: boolean) => {
      set({ useVoiceMode: enabled });
    },
    setProcessingVoice: (processing: boolean) => {
      set({ isProcessingVoice: processing });
    },
    setTtsMessages: (messages: { text: string; isLast: boolean }[]) => {
      set({ ttsMessages: messages });
    },
    setSettingsOpen: (state: boolean) => {
      set({
        settingsOpen: state,
      });
    },
    deleteInputs: async (timestamp: number) => {
      await delete_inputs(timestamp);
      return true;
    },
    setShareModalOpen: (open: boolean) => set({ shareModalOpen: open }),
    fetchPersonalAccessToken: async () => {
      const token = await get_personal_access_token();
      set({ personalAccessToken: token });
    },
    reset: () => set(initialState),
  };
};

export const useAppStore = create<AppStore>()(baseStore);
