import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const redirectParamsSchema = z.object({
  code: z.string().catch(''),
  state: z.string().catch(''),
  error: z.string().optional(),
  error_description: z.string().optional(),
});

export const Route = createFileRoute('/auth-callback/')({
  component: RouteComponent,
  validateSearch: (search) => redirectParamsSchema.parse(search),
});

function RouteComponent() {
  const params = Route.useSearch();
  window.opener?.postMessage(`auth-callback:${JSON.stringify(params)}`);
  window.close();
  return <div>Authenticating...</div>;
}
