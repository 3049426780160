import './styles/index.css';
import { Theme } from '@radix-ui/themes';
import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider } from 'jotai';
import { DevTools as JotaiDevTools } from 'jotai-devtools';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { HydrateAtoms } from './components/HydrateAtoms';
import { getEnvVar } from './env';
import { RouterProvider } from './providers/RouterProvider';
import { queryClient } from './query/client';
import { flagsLoadedAtom } from './stores/atoms/featureFlags';
import jotaiStore from './stores/jotaiStore';

const isDev = getEnvVar('VITE_ENV') === 'development';

if (isDev) import('jotai-devtools/styles.css');

const isRunningInLocalhost = getEnvVar('IS_RUNNING_IN_LOCALHOST');

posthog.init(getEnvVar('VITE_POSTHOG_API_KEY'), {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always',
  autocapture: !isDev && !isRunningInLocalhost,
  capture_pageview: false,
});

posthog.onFeatureFlags(() => {
  jotaiStore.set(flagsLoadedAtom, true);
});

posthog.reloadFeatureFlags();

// TODO: Add environment tag while initializing sentry
Sentry.init({
  dsn: 'https://96f904e733472b528b0154cd44021a41@o4507576431542272.ingest.us.sentry.io/4508289503789056',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['/^.*tauri.*$/', /^https:\/\/lilbird\.co/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !isDev && !isRunningInLocalhost,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <Provider store={jotaiStore}>
      <PostHogProvider client={posthog}>
        <QueryClientProvider client={queryClient}>
          <Theme>
            <HydrateAtoms>
              {shouldRenderDevTools() && (
                <>
                  <ReactQueryDevtools
                    client={queryClient}
                    initialIsOpen={false}
                    buttonPosition="top-right"
                  />
                  <JotaiDevTools store={jotaiStore} position="bottom-right" />
                </>
              )}
              <RouterProvider />
            </HydrateAtoms>
          </Theme>
        </QueryClientProvider>
      </PostHogProvider>
    </Provider>
  </React.StrictMode>
);

function shouldRenderDevTools() {
  // Don't show DevTools on meeting-status page
  if (window.location.pathname === '/meeting-status') {
    return false;
  }

  // Check for ENABLE_DEVTOOLS environment variable or URL param
  const enableDevTools =
    getEnvVar('ENABLE_DEVTOOLS') ||
    new URLSearchParams(window.location.search).has('devtools');

  return !!enableDevTools;
}
