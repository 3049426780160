import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface ButtonProps {
  children: React.ReactNode;
  onPress?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  isSubmitting?: boolean;
  variant?: 'default' | 'outline' | 'warning';
}

const Button = ({
  children,
  onPress,
  disabled = false,
  type = 'button',
  className = '',
  isSubmitting = false,
  variant = 'default',
}: ButtonProps) => {
  const baseStyles =
    'flex items-center justify-center opacity-90 hover:opacity-100 disabled:hover:opacity-90 rounded text-xs font-mono whitespace-nowrap uppercase text-darkPrimary p-3';

  const variantStyles = {
    default: `${disabled ? 'bg-darkFieldSelected' : 'bg-cta-gradient '}`,
    warning: 'bg-darkRed px-4',
    outline: 'border border-darkLine',
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      onClick={onPress}
      type={type}
      disabled={disabled}
    >
      {isSubmitting ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
