import { once } from '@tauri-apps/api/event';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';

import { IS_AUTH_VALID_QUERY_KEY } from '@/query/auth';
import { queryClient } from '@/query/client';
import { router } from '@/router';
import { isAuthValidQueryAtom } from '@/stores/atoms/auth';
import { listenWithAbort } from '@/utils/abort';
import { AUTH_TOKEN_REFRESH_INTERVAL, isAuthenticated } from '@/utils/auth';
import { AppCapability, isCapable } from '@/utils/capabilities';
import { debugToast } from '@/utils/toast';

export function useAuthTokensRefresh() {
  const { error } = useAtomValue(isAuthValidQueryAtom);

  useEffect(() => {
    if (!isCapable(AppCapability.MainWindowExclusive)) return;

    const id = setInterval(() => {
      if (!isAuthenticated()) return;

      debugToast('refreshing auth tokens...');

      queryClient.invalidateQueries({
        queryKey: IS_AUTH_VALID_QUERY_KEY,
        refetchType: 'all',
      });
    }, AUTH_TOKEN_REFRESH_INTERVAL);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (!isCapable(AppCapability.MainWindowExclusive)) return;

    if (!isCapable(AppCapability.BackgroundAuthTokenRefresh)) return;

    const controller = new AbortController();

    listenWithAbort(
      'auth_tokens_did_refresh',
      () =>
        queryClient.invalidateQueries({
          queryKey: IS_AUTH_VALID_QUERY_KEY,
          refetchType: 'all',
        }),
      controller.signal
    );

    once('auth_tokens_did_expire', () =>
      router.navigate({ to: '/error/session-expired' })
    );

    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (isCapable(AppCapability.BackgroundAuthTokenRefresh)) return;

    if ((error as Error)?.name === 'TokenExpiredError')
      router.navigate({ to: '/error/session-expired' });
  }, [error]);
}
